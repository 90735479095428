import { render, staticRenderFns } from "./courses.vue?vue&type=template&id=4ce6bb8b&lang=pug&"
import script from "./courses.vue?vue&type=script&lang=js&"
export * from "./courses.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ScrollHint: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/scroll-hint.vue').default,ModalCoursePrices: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/modal-course-prices.vue').default})
