//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import SiteFooter from '~/components/footer'
import svgSymbol from '~/components/svg-symbol'
// import AdminPanel from '~/components/admin-panel'
import RestStringDummy from '~/components/rest-string-dummy'
import RestStringLinkDummy from '~/components/rest-string-link-dummy'
// import Burger from '~/components/menu-burger'
import Menu from '~/components/menu-no-edit'
import ExtForm from '~/components/form123'
import ModalLoader from '~/components/modal-loader'
import ModalCoursePrices from '~/components/modal-course-prices'
import SmoothScrollbar from '~/lib/smooth-scrollbar-gsap/index.js';
// import '~/lib/smooth-scrollbar-gsap/dist/smooth-scrollbar.js';
// import HeaderMetaMixin from '~/mixins/header-meta'
import ParseModalUrlMixin from '~/mixins/parse-modal-url'
import ExtFormMixin from '~/mixins/external-form'
import ScrollToAnchor from '~/mixins/scroll-to-anchor'
import ScrollingMixin from '~/mixins/scrolling'
// import FreeQuoteFormMixin from '~/mixins/free-quote-form'
import ScrollHint from '~/components/scroll-hint'
import LazyHydrate from 'vue-lazy-hydration';

const dev = process.env.NODE_ENV === 'development';
const baseUrl = '';//'/courses';

let menuItems = Object.freeze( [
	{ type: 'logo', url: '/learning-school', label: 'Learning School'},
	{ type: 'spacer'},
	{ type: 'label', mobileLabel: 'Navigate'},
	{ type: 'link', label: 'Home', mobileLabel: 'Home', url: baseUrl+'#home', is_external: 0 },
	{ type: 'link', label: 'Courses', mobileLabel: 'Courses', url: baseUrl+'#courses', is_external: 0 },
	{ type: 'link', label: 'Instructors', mobileLabel: 'Instructors', url: baseUrl+'#instructors', is_external: 0 },
	{ type: 'link', label: 'FAQs', mobileLabel: 'FAQs', url: baseUrl+'#faqs', is_external: 0 },
	{ type: 'link', label: 'Contact', mobileLabel: 'Contact', url: baseUrl+'#contact', is_external: 0 },
	{ type: 'spacer'},
	{ type: 'link', label: 'el-translations.com', mobileLabel: 'el-translations.com', url: 'https://el-translations.com', is_external: 1 },
] );

export default
{

	mixins: [/* HeaderMetaMixin, */ ParseModalUrlMixin, ScrollToAnchor, ScrollingMixin, ExtFormMixin ],

	components:
	{
		LazyHydrate,
		// you *MUST* declare components before you can use them
		SiteFooter,
		svgSymbol,
		ExtForm,
		// AdminPanel,
		// RestString,
		// RestStringLink,
		'rest-string': RestStringDummy,
		'rest-string-link': RestStringLinkDummy,
		'site-menu': Menu,
		// Burger
		'modal': ModalLoader,
		ScrollHint,
		ModalCoursePrices,
	},

	data()
	{
		return {
			// showTopBanner: false,
			// showMobileMenu: false,
			showModal: 0,
			showQuoteForm: false,
			menuItems,
			coursePrices: null,
		};
	},

	// head()
	// {
	// 	return {
	// 		title: 'Corporate',

	// 		bodyAttrs: {
	// 			class: [
	// 				// this.showMobileMenu ? 'show-mobile-menu' : '',
	// 				this.showModal > 0 ? 'show-modal' : '',
	// 				// this.topBanner ? 'has-topbanner' : '',
	// 				]
	// 		}
	// 	}
	// },

	watch:
	{
		// showMobileMenu()
		// {
		// 	debugger;
		// 	this.$meta().refresh();
		// }

	},

	computed:
	{
		...mapGetters(['menuData', 'getString', 'commonWords', 'topBanner', 'lang', 'langShort', 'allLanguages', 'windowWidth', 'isMobile', 'isTablet', 'isDesktop', 'hasWhiteLogo']),
	},

	// asyncData is called only on pages, not on layouts

	// beforeMount()
	// {
	// 	// this.$meta().refresh();
	// },

	beforeDestroy()
	{

		// this.$root.$off('showMobileMenu');
		this.$root.$off('showModal');
		this.$root.$off('showCoursePrices');
	},

	// this is called when the children have been mounted and the component is ready
	mounted()
	{
		// this.$root.$on('showMobileMenu', (val) => { this.showMobileMenu = val } );
		this.$root.$on('showModal', (val) => { this.showModal += val ? 1 : -1 } );
		this.$root.$on('showCoursePrices', (val) => { this.coursePrices = val || null; } );
		consola.debug('cookies: ', this.$cookies);
	},

	methods:
	{
		...mapActions(['setLang']),

		changeLang(item)
		{
			// consola.debug('changing lang to ', item.code);
			consola.debug('changing lang to ', item.lang_code);
			const isAcademy = window.location.hostname.indexOf('academy') > -1;
			if (isAcademy)	return;
			// this.setLang({ lang: item.lang_code });
			var parts = window.location.pathname.split('/');
			while( !parts[0] ) parts.shift();
			// remove the 1st element
			parts.shift();
			this.$router.push( `/${item.lang_code}/` + parts.join('/') )
		}
	}


}
