//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import SiteFooter from '~/components/footer'
import svgSymbol from '~/components/svg-symbol'
// import AdminPanel from '~/components/admin-panel'
import RestStringDummy from '~/components/rest-string-dummy'
import RestStringLinkDummy from '~/components/rest-string-link-dummy'
// import Burger from '~/components/menu-burger'
import Menu from '~/components/menu-no-edit'
import ModalLoader from '~/components/modal-loader'
import SmoothScrollbar from '~/lib/smooth-scrollbar-gsap/index.js';
import ExtForm from '~/components/form123'
// import '~/lib/smooth-scrollbar-gsap/dist/smooth-scrollbar.js';
// import HeaderMetaMixin from '~/mixins/header-meta'
import ParseModalUrlMixin from '~/mixins/parse-modal-url'
import ExtFormMixin from '~/mixins/external-form'
import ScrollToAnchor from '~/mixins/scroll-to-anchor'
import ScrollingMixin from '~/mixins/scrolling'
// import FreeQuoteFormMixin from '~/mixins/free-quote-form'
// import FreeQuoteFormMixin from '~/mixins/123form'
import ScrollHint from '~/components/scroll-hint'
import LazyHydrate from 'vue-lazy-hydration';

const dev = process.env.NODE_ENV === 'development';
const baseUrl = '';//'/corporate';

let menuItems = Object.freeze( [
	{ type: 'logo', url: '/'},
	{ type: 'spacer'},
	{ type: 'link', label: '', mobileLabel: 'Home', url: baseUrl, is_external: 0 },
	{ type: 'link', label: 'Services', mobileLabel: 'Services', url: baseUrl+'#services', is_external: 0 },
	{ type: 'link', label: 'Industry Expertise', mobileLabel: 'Industry Expertise', url: baseUrl+'#expertise', is_external: 0 },
	{ type: 'link', label: 'About', mobileLabel: 'About', url: baseUrl+'#about', is_external: 0 },
	{ type: 'link', label: 'Careers', mobileLabel: 'Careers', url: baseUrl+'#careers', is_external: 0 },
	{ type: 'link', label: 'Contact', mobileLabel: 'Contact', url: baseUrl+'#contact', is_external: 0 },
	// { label: 'TRAINING', url: 'http://www.el-translations.com/kedivim/', target: "_blank" },
	{ type: 'spacer'},
	{ type: 'link', label: 'Training', mobileLabel: 'Learning School', url: '/learning-school/', is_external: 1 },
	{ type: 'spacer'},
	{ type: 'lang-list'},
	// { type: 'form-button', label: 'Free Quote', url: '/learning-school/' },
] );

// let mobileMenuItems = Object.freeze( [
// 	{ type: 'link', label: 'HOME', url: baseUrl },
// 	{ type: 'link', label: 'SERVICES', url: baseUrl+'#services' },
// 	{ type: 'link', label: 'INDUSTRIES', url: baseUrl+'#expertise' },
// 	{ type: 'link', label: 'ABOUT', url: baseUrl+'#about' },
// 	{ type: 'link', label: 'CAREERS', url: baseUrl+'#careers' },
// 	{ type: 'link', label: 'CONTACT', url: baseUrl+'#contact' },
// ] );

export default
{

	mixins: [/* HeaderMetaMixin, */ ParseModalUrlMixin, ScrollToAnchor, ScrollingMixin, ExtFormMixin, /* FreeQuoteFormMixin */],

	components:
	{
		LazyHydrate,
		// you *MUST* declare components before you can use them
		SiteFooter,
		svgSymbol,
		// AdminPanel,
		// RestString,
		// RestStringLink,
		'rest-string': RestStringDummy,
		'rest-string-link': RestStringLinkDummy,
		'site-menu': Menu,
		// Burger
		'modal': ModalLoader,
		ExtForm,
		ScrollHint,
	},

	data()
	{
		return {
			// showTopBanner: false,
			// showMobileMenu: false,
			showModal: 0,
			showExtForm: false,
			// mobileMenuItems,
			// formLocaleUrls: {},
			formUrl: '',
		};
	},

	head()
	{
		return {
			title: 'Corporate',

			// script:
			// [
			// 	{
			// 		type: "text/javascript",
			// 		defer: true,
			// 		src: "//www.123formbuilder.com/embed/5290441.js",
			// 		'data-role': "form",
			// 		'data-default-width': "650px"
			// 	}
			// ],
			bodyAttrs: {
				class: [
					// this.showMobileMenu ? 'show-mobile-menu' : '',
					this.showModal > 0 ? 'show-modal' : '',
					// this.topBanner ? 'has-topbanner' : '',
					]
			}
		}
	},

	watch:
	{
		// showMobileMenu()
		// {
		// 	debugger;
		// 	this.$meta().refresh();
		// }

		// scrollTop(newVal)
		// {
		// 	consola.debug('scrolled to Top: ', newVal)
		// }

	},

	computed:
	{
		...mapGetters(['menuData', 'getString', 'commonWords', 'topBanner', 'lang', 'langShort', 'allLanguages', 'windowWidth', 'isMobile', 'isTablet', 'isDesktop', 'hasWhiteLogo']),

		// menuItems()
		// {
		// 	const form_url = (this.lang=='el') ? '//www.123formbuilder.com/my-contact-form-5303444.html'
		// 					: '//www.123formbuilder.com/my-contact-form-5290441.html';

		// 	return [
		// 			...menuItems,
		// 			{ type: 'form-button', label: 'Free Quote', url: form_url  },
		// 			]
		// }
	},

	// asyncData is called only on pages, not on layouts


	// beforeMount()
	// {
	// 	this.$meta().refresh();
	// },

	beforeDestroy()
	{

		// this.$root.$off('showMobileMenu');
		this.$root.$off('showModal');
	},

	// this is called when the children have been mounted and the component is ready
	mounted()
	{
		/***  ROUTER TESTS */
		// const currentRoute = this.$route;
		// // const routes = $nuxt.$router.getRoutes();
		// const main = {...$nuxt.$router.getRoutes().find(x => x.name=="main-domain-lang") };
		// // main.alias = ['/hello-world'];
		// // main.matchAs = currentRoute.fullPath;
		// main.path = '/1/2/3/4/5/6/7/8/hello-world';
		// main.name = 'urlDispatcher-' + main.name;
		// main.props = {...currentRoute.params};
		// $nuxt.$router.addRoute(main);
		// consola.debug('routes: ', $nuxt.$router.getRoutes());

		// if (this.$route.fullPath=='/el')
		// 	setTimeout( () =>
		// 	{
		// 		debugger;
		// 		$nuxt.$router.push({ path: main.path });

		// 	}, 5000);

		/***  ROUTER TESTS */

		// this.$root.$on('showMobileMenu', (val) => { this.showMobileMenu = val } );
		this.$root.$on('showModal', (val) => { this.showModal += val ? 1 : -1 } );
		consola.debug('cookies: ', this.$cookies)

		// this.$root.$on( 'show-quote-form', () => {
		// 	const urls = {
		// 			en: '//www.123formbuilder.com/my-contact-form-5290441.html',
		// 			el: '//www.123formbuilder.com/my-contact-form-5303444.html',
		// 		};

		// 	this.formUrl = urls[this.lang] || '';

		// 	this.showExtForm = true;
		// } )

		// consola.debug('menu: ', JSON.stringify(this.menuItems))

		this.$root.$on( 'show-contact-form', () => {
			const urls = {
					en: '//www.123formbuilder.com/my-contact-form-5303463.html',
					el: '//www.123formbuilder.com/my-contact-form-5303482.html',
				};
			this.formUrl = urls[this.lang] || '';
			this.showExtForm = true;
		} )

		// this.$root.$on( 'show-external-form', (params) =>
		// {
		// 	// this.formLocaleUrls = params || {};
		// 	this.formUrl = params || '';
		// 	this.showExtForm = true;
		// });
	},

	beforeDestroy()
	{
		this.$root.$off( 'show-quote-form' )
		this.$root.$off( 'show-contact-form' )
		// this.$root.$off( 'show-external-form' )
	},

	methods:
	{
		...mapActions(['setLang']),


		changeLang(item)
		{
			// consola.debug('changing lang to ', item.code);
			consola.debug('changing lang to ', item.lang_code);
			const isAcademy = window.location.hostname.indexOf('academy') > -1;
			if (isAcademy)	return;
			// this.setLang({ lang: item.lang_code });
			var parts = window.location.pathname.split('/');
			while( !parts[0] ) parts.shift();
			// remove the 1st element
			parts.shift();
			this.$router.push( `/${item.lang_code}/` + parts.join('/') )
		}
	}


}
