//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
	name: 'NuxtError',
	layout: 'corporate',

	props: {
		error: {
			type: Object,
			default: null
		}
	},
	computed:
	{
		...mapGetters(['getString', 'topBanner', 'lang', 'langShort']),
		statusCode ()
		{
			return (this.error && this.error.statusCode) || 500
		},
		message ()
		{
			return this.error.message || '<%= messages.client_error %>'
		},

		route()
		{
			return this.$route
		}

	},

	head ()
	{
		return {
			title: this.message,
			meta: [
				{
					name: 'viewport',
					content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no'
				}
			]
		}
	},


	beforeMount()
	{
		this.getMenuFooter();
		this.setWindowWidth(window.innerWidth);
	},

	mounted()
	{
		// setTimeout(
		// 	() =>
		// 	{
		// 		this.$router.push({ path: `/${this.lang}/corporate` })
		// 	}
		// 	, 2000
		// )
		(consola.warn)(this.error);
	},

	methods:
	{
		...mapMutations(['setWindowWidth']),

		async getMenuFooter() 
		{
			let result = await this.$apiGet('page-slug/', { slug: '/', site_id: 1, locale: 'el' } );

			if (!result || !result.data)
			{
				return;
			}

			const pageData = result.data[0];

			if (pageData && pageData.site)
				this.$store.commit('setSiteData', pageData.site)

			const menu = pageData.menu && pageData.menu.json || [];
			this.$store.commit('setPageMenu', menu);

			const footer = pageData.footer && pageData.footer.json || [];
			this.$store.commit('setPageFooter', footer);
		},
	}
}
