//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import SiteFooter from '~/components/footer'
// import AdminPanel from '~/components/admin-panel'
import ExtForm from '~/components/form123'
import ModalLoader from '~/components/modal-loader'
import svgSymbol from '~/components/svg-symbol'
import RestString from '~/components/rest-string'
import RestStringLink from '~/components/rest-string-link'
import Menu from '~/components/menu-admin'

// import HeaderMetaMixin from '~/mixins/header-meta'
import ParseModalUrlMixin from '~/mixins/parse-modal-url'
import ExtFormMixin from '~/mixins/external-form'
import ScrollToAnchor from '~/mixins/scroll-to-anchor'
import ScrollingMixin from '~/mixins/scrolling'
// import FreeQuoteFormMixin from '~/mixins/free-quote-form'
import ScrollHint from '~/components/scroll-hint'
import LazyHydrate from 'vue-lazy-hydration';


const dev = process.env.NODE_ENV === 'development';
const baseUrl = '';//'/corporate';

let menuItems = Object.freeze( [
	{ type: 'logo', url: '/'},
	{ type: 'spacer'},
	{ type: 'link', label: '', mobileLabel: 'Home', url: baseUrl, is_external: 0 },
	{ type: 'link', label: 'Services', mobileLabel: 'Services', url: baseUrl+'#services', is_external: 0 },
	{ type: 'link', label: 'Industry Expertise', mobileLabel: 'Industry Expertise', url: baseUrl+'#expertise', is_external: 0 },
	{ type: 'link', label: 'About', mobileLabel: 'About', url: baseUrl+'#about', is_external: 0 },
	{ type: 'link', label: 'Careers', mobileLabel: 'Careers', url: baseUrl+'#careers', is_external: 0 },
	{ type: 'link', label: 'Contact', mobileLabel: 'Contact', url: baseUrl+'#contact', is_external: 0 },
	// { label: 'TRAINING', url: 'http://www.el-translations.com/kedivim/', target: "_blank" },
	{ type: 'spacer'},
	{ type: 'link', label: 'Training', mobileLabel: 'Learning School', url: '/learning-school/', is_external: 1 },
	{ type: 'spacer'},
	{ type: 'lang-list'},
	// { type: 'form-button', label: 'Free Quote', url: '/learning-school/' },
] );

export default
{
	mixins: [
		/* HeaderMetaMixin, */ ParseModalUrlMixin, ScrollToAnchor, ScrollingMixin, ExtFormMixin,
	],

  components:
	{
		LazyHydrate,
		// you *MUST* declare components before you can use them
		SiteFooter,
		// AdminPanel,
		ExtForm,
		svgSymbol,
		RestString,
		RestStringLink,
		'site-menu': Menu,
		'modal': ModalLoader,
		ScrollHint,
	},

	data()
	{
		return {
			// showMobileMenu: false,
			menuItems,
		}
	},

	// head()
	// {
	// 	return {
	// 		bodyAttrs: {
	// 			class: [this.showMobileMenu ? 'show-mobile-menu' : '']
	// 		}
	// 	}
	// },

	computed:
	{
		...mapGetters(['menuData', 'getString', 'lang', 'langShort', 'allLanguages', 'windowWidth', 'isMobile', 'isTablet', 'isDesktop', 'hasWhiteLogo']),
	},

	// asyncData is called only on pages, not on layouts


	// this is called when the children have been mounted and the component is ready
	mounted()
	{
		// consola.debug('yaml test: ', yamlData);
		consola.debug('menuData: ', this.menuData);
		consola.debug('allLanguages: ', this.allLanguages);
		consola.debug('lang: ', this.lang);
		consola.debug('langShort: ', this.langShort);
		// consola.debug('commonWords: ', this.commonWords);
		// consola.debug('info: ', this.info);

		// this.$root.$on('showMobileMenu', (val) => { this.showMobileMenu = val } );


	},

	methods:
	{
		...mapMutations(['setStringTable']),
		...mapActions(['setLang']),

		changeLang(item)
		{
			consola.debug('changing lang to ', item.lang_code);
			const isAcademy = window.location.hostname.indexOf('academy') > -1;
			if (isAcademy)	return;
			// this.setLang({ lang: item.lang_code });
			var parts = window.location.pathname.split('/').filter(x => x.length);
			while( !parts[0] ) parts.shift();
			// remove the 1st element
			parts.shift();
			this.$router.push( `/${item.lang_code}/` + parts.join('/') )
		}
	}


}
