//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import SiteFooter from '~/components/footer-admin'
import svgSymbol from '~/components/svg-symbol'
import AdminPanel from '~/components/admin-panel'
import RestString from '~/components/rest-string'
import RestStringLink from '~/components/rest-string-link'
// import Burger from '~/components/menu-burger'
import Menu from '~/components/menu-admin'
import ExtForm from '~/components/form123'
import ModalLoader from '~/components/modal-loader'
import SmoothScrollbar from '~/lib/smooth-scrollbar-gsap/index.js';
// import '~/lib/smooth-scrollbar-gsap/dist/smooth-scrollbar.js';
// import HeaderMetaMixin from '~/mixins/header-meta'
import ParseModalUrlMixin from '~/mixins/parse-modal-url'
import ExtFormMixin from '~/mixins/external-form'
import ScrollToAnchor from '~/mixins/scroll-to-anchor'
import ScrollingMixin from '~/mixins/scrolling'


const dev = process.env.NODE_ENV === 'development';
const baseUrl = '';//'/corporate';

let menuItems = Object.freeze( [
	{ type: 'logo', url: '/'},
	{ type: 'spacer'},
	{ type: 'link', label: '', mobileLabel: 'Home', url: baseUrl, is_external: 0 },
	{ type: 'link', label: 'Services', mobileLabel: 'Services', url: baseUrl+'#services', is_external: 0 },
	{ type: 'link', label: 'Industry Expertise', mobileLabel: 'Industry Expertise', url: baseUrl+'#expertise', is_external: 0 },
	{ type: 'link', label: 'About', mobileLabel: 'About', url: baseUrl+'#about', is_external: 0 },
	{ type: 'link', label: 'Careers', mobileLabel: 'Careers', url: baseUrl+'#careers', is_external: 0 },
	{ type: 'link', label: 'Contact', mobileLabel: 'Contact', url: baseUrl+'#contact', is_external: 0 },
	// { label: 'TRAINING', url: 'http://www.el-translations.com/kedivim/', target: "_blank" },
	{ type: 'spacer'},
	{ type: 'link', label: 'Training', mobileLabel: 'Learning School', url: '/learning-school/', is_external: 1 },
	{ type: 'spacer'},
	{ type: 'lang-list'},
	// { type: 'form-button', label: 'Free Quote', url: '/learning-school/' },
] );

export default
{
	mixins: [/* HeaderMetaMixin, */ ParseModalUrlMixin, ScrollToAnchor, ScrollingMixin, ExtFormMixin],

	components:
	{
		// you *MUST* declare components before you can use them
		SiteFooter,
		svgSymbol,
		AdminPanel,
		ExtForm,
		RestString,
		RestStringLink,
		'site-menu': Menu,
		'modal': ModalLoader,
		// Burger,
	},

	data()
	{
		return {
			// showTopBanner: false,
			// scrollTop: true,
			// showMobileMenu: false,
			showModal: 0,
			// smoothScrollbar: null,
			// scrollCallback: null,
			menuItems,
		};
	},

	// head()
	// {
	// 	return {
	// 		title: '',
	// 		bodyAttrs: {
	// 			class: [
	// 				// this.showMobileMenu ? 'show-mobile-menu' : '',
	// 				this.showModal > 0 ? 'show-modal' : '',
	// 				// this.topBanner ? 'has-topbanner' : '',
	// 				]
	// 		}
	// 	}
	// },

	watch:
	{
		// showMobileMenu()
		// {
		// 	debugger;
		// 	this.$meta().refresh();
		// },

		topBanner(newVal)
		{
			if (newVal)
			{
				this.showTopBanner = true;
			}
		}

	},

	computed:
	{
		...mapGetters(['menuData', 'getString', 'commonWords', 'topBanner', 'lang', 'langShort', 'allLanguages', 'windowWidth', 'isMobile', 'isTablet', 'isDesktop', 'hasWhiteLogo']),
	},

	// asyncData is called only on pages, not on layouts

	// beforeMount()
	// {
	// 	this.$meta().refresh();
	// },

	beforeDestroy()
	{
		// this.$root.$off('scrolledToTop');
		this.$root.$off('showMobileMenu');
		this.$root.$off('showModal');
		// this.$root.$off('showTopBanner');
	},

	// this is called when the children have been mounted and the component is ready
	mounted()
	{
		this.$root.$on('showMobileMenu', (val) => { this.showMobileMenu = val } );
		this.$root.$on('showModal', (val) => { this.showModal += val ? 1 : -1 } );
	},

	methods:
	{
		...mapActions(['setLang']),


		changeLang(item)
		{
			// consola.debug('changing lang to ', item.code);
			consola.debug('changing lang to ', item.lang_code);
			const isAcademy = window.location.hostname.indexOf('academy') > -1;
			if (isAcademy)	return;
			// this.setLang({ lang: item.lang_code });
			var parts = window.location.pathname.split('/');
			while( !parts[0] ) parts.shift();
			// remove the 1st element
			parts.shift();
			this.$router.push( `/${item.lang_code}/` + parts.join('/') )
		}
	}


}
